import * as React from 'react';

import Grid from '@mui/material/Unstable_Grid2';
// import Selector from "../resumeEditor/selector/Selector";
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
// import Container from "@mui/material/Container";
// import { Typography } from "@mui/material";
import debounce from 'lodash.debounce';
import BottomNavBar from 'components/BottomNavBar';
import AllInfsWindow from 'components/AllInfsWindow';
import ResumeEditor from 'components/ResumeEditor';
import { nanoid } from 'nanoid';
import { useImmer } from 'use-immer';
import ResumeNameTextFiled from '../resumeEditor/ResumeNameTextField';
import ResumeViewer from '../components/ResumeViewer';
import {
  getResumeById,
  getAllSources,
  getTemplateById,
  updateResumeById,
  addNewInfo,
  getJobDecriptionByResumeId,
} from '../api';
// import { CategoryRounded } from "@mui/icons-material";
/**
 * Render /edit/:id page
 * @returns a resume editor page of resume with id
 *
 */
export default function EditorPage() {
  // toggle hide category

  // get resume id from url
  const { resumeId } = useParams();
  // all source [entry1,entry2...]
  const [sources, setSources] = React.useState(undefined);
  // resume {id, name, cateogry: [list of category]}, data: {category: [list of entry]}
  const [resume, updateResume] = useImmer(undefined);
  // one resume will have one job description
  // indicating the status of left side drawer
  // const [openMenu, setOpenMenu] = React.useState(false);
  // template
  const [template, setTemplate] = React.useState({});
  // template id
  const [jobDescription, setJobDescription] = React.useState(undefined);
  const [view, setView] = React.useState(2);
  const [hiddenCategories, updateHiddenCategories] = useImmer({});

  // select category to display
  // const informationToDisplay = React.useMemo(() => {
  //   if (resume === undefined || sources === undefined) {
  //     return [];
  //   }
  //   return {...resume, resume}
  // fetch data from backend
  React.useEffect(() => {
    async function loadDataFromDB() {
      let response = await getResumeById(resumeId);
      if (response.status !== 200) {
        console.log('error');
        return;
      }

      // give all the data an id if not existing
      const userInformations = response.data.data.map((entry) => {
        if (entry.id === undefined) {
          return {
            ...entry,
            id: nanoid(),
          };
        }
        return entry;
      });
      response.data.data = userInformations;
      const newTemplate = await getTemplateById(0);
      setTemplate(newTemplate);

      // set a layout if undefined
      if (!response.data.layout || response.data.layout.length === 0) {
        response.data.layout = newTemplate;
      }

      // add hiddenCategories if not exist
      // if (response.data.hiddenCategories === undefined) {
      //   response.data.hiddenCategories = {};
      // }

      updateResume(response.data);
      response = await getAllSources();
      if (response.status !== 200) {
        console.log('error');
        return;
      }

      setSources(response.data);
      // load template
      // load job description
      const newJobDescription = await getJobDecriptionByResumeId(resumeId);
      setJobDescription(newJobDescription);
    }
    loadDataFromDB();
  }, [resumeId, updateResume]);

  const debouncedSyncResume = React.useMemo(
    () => debounce(() => {
      updateResumeById(resumeId, resume).then((response) => {
        if (response.status !== 200) {
          console.log('error');
        }
      });
    }, 1000),
    [resumeId, resume],
  );

  // Define syncResume as a separate function
  const syncResume = React.useCallback(() => {
    debouncedSyncResume(); // Call the debounced function here
  }, [debouncedSyncResume]);
  // sync resume to backend
  React.useEffect(() => {
    if (resume === undefined) {
      return;
    }
    syncResume();
  }, [resume, resumeId, syncResume]);

  /**
   * update resume name on change
   * @param {*} e event from text field
   */
  const handleUpdateResumeName = React.useCallback(
    (newName) => {
      const newResume = { ...resume, name: newName };

      async function _updateResume() {
        const incomingResume = await updateResumeById(resumeId, newResume);
        updateResume(incomingResume.data);
        // notify user of save
      }

      _updateResume(newResume);
    },
    [resume, resumeId, updateResume],
  );

  /**
   * add the source back to source from resume,
   * This should be only possible if the entry is not already in the source
   * @param {*} source {id, name, category, data}, id needed to replace the old one in the resume
   */
  function handleAddSourceFromResume(source) {
    // check if the source is already in the sources
    const isSourceInSources = sources.some((e) => e.id === source.id);
    if (isSourceInSources) {
      console.log('already in sources');
      return;
    }
    async function _addSource() {
      // create a new source
      const response = await addNewInfo(source);
      if (response.status !== 200) {
        console.log('error');
        return;
      }
      setSources([...sources, response.data]);
      // update resume by replace the old one with new one

      const newResume = {
        ...resume,
        data: resume.data.map((entry) => {
          if (entry.id === source.id) {
            return response.data;
          }
          return entry;
        }),
      };
      updateResume(newResume);
    }
    _addSource(source);
  }
  // create a debouced version of handleUpdateResumeName to limit the number of api call
  const debouncedHandleUpdateResumeName = React.useMemo(
    () => debounce(handleUpdateResumeName, 300),
    [handleUpdateResumeName],
  );

  const sortedInfos = React.useMemo(() => {
    if (sources === undefined) {
      return [];
    }
    const copy = [...sources];
    copy.sort((a, b) => b.last_edit_date - a.last_edit_date);
    return copy;
  }, [sources]);

  /**
   * hanlde move user information up in a category
   * @param {*} id
   */
  function handleMoveInfoUp(id) {
    const newData = [...resume.data];
    const moveThisUp = newData.find((entry) => entry.id === id);
    const i = newData.indexOf(moveThisUp);
    const moveTo = newData
      .slice(0, i)
      .findLastIndex((entry) => entry.category === moveThisUp.category);
    newData.splice(i, 1);
    newData.splice(moveTo, 0, moveThisUp);
    updateResume((draft) => {
      // eslint-disable-next-line no-param-reassign
      draft.data = newData;
    });
  }

  /**
   * handle to move user information down in a category
   * @param {*} id
   */
  function handleMoveInfoDown(id) {
    const newData = [...resume.data];
    const moveThisDown = newData.find((entry) => entry.id === id);
    const i = newData.indexOf(moveThisDown);
    const moveTo = newData
      .slice(i + 1)
      .findIndex((entry) => entry.category === moveThisDown.category);
    const insertIndex = moveTo === -1 ? newData.length : i + 1 + moveTo;
    newData.splice(i, 1);
    newData.splice(insertIndex, 0, moveThisDown);
    updateResume((draft) => {
      // eslint-disable-next-line no-param-reassign
      draft.data = newData;
    });
  }

  /**
   * handle to delete user informationhjng   * @param {*} id
   */
  function handleDeleteInfo(id) {
    const newData = [...resume.data];
    const deleteThis = newData.find((entry) => entry.id === id);
    const i = newData.indexOf(deleteThis);
    newData.splice(i, 1);
    updateResume((draft) => {
      // eslint-disable-next-line no-param-reassign
      draft.data = newData;
    });
  }

  /**
   * toggle hide or display a category in resume
   */
  function handleHideCategory(category) {
    updateHiddenCategories((draft) => {
      // create category to if not exist
      if (draft === undefined) {
        // eslint-disable-next-line no-param-reassign
        draft[category] = true;
      } else {
        // already exist just toggle
        // eslint-disable-next-line no-param-reassign
        draft[category] = hiddenCategories[category] === undefined
          ? true
          : !hiddenCategories[category];
      }
    });
  }

  async function handleResumeOnChange(action, data) {
    if (action === 'add') {
      // add new info to resume
      const newResume = { ...resume, data: [...resume.data, ...data] };
      const response = await updateResumeById(resumeId, newResume);
      if (response.status !== 200) {
        console.log('error');
        return;
      }
      updateResume(response.data);
    } else if (action === 'edit') {
      updateResume((draft) => {
        const infoWithId = draft.data.find((entry) => entry.id === data.id);
        infoWithId.data[data.fieldName] = data.content;
      });
    } else if (action === 'movedown' || action === 'moveup') {
      // move category up and down
      const index = resume.layout.findIndex((item) => item[0] === data);
      if (index === -1) {
        console.log('Object not found in the list.');
        return;
      }
      if (action === 'moveup') {
        // move category up
        const newLayout = [...resume.layout];
        const moveThis = newLayout.splice(index, 1); // Remove the current item
        newLayout.splice(index - 1, 0, moveThis[0]); // Insert the current item at the new position
        updateResume((prev) => ({
          ...prev,
          layout: newLayout,
        }));
      } else if (action === 'movedown') {
        // move category
        const newLayout = [...resume.layout];
        const moveThis = newLayout.splice(index, 1); // Remove the current item
        newLayout.splice(index + 1, 0, moveThis[0]); // Insert the current item at the new position
        updateResume((prev) => ({
          ...prev,
          layout: newLayout,
        }));
      }
    } else if (action === 'hide') {
      // hide from layout
    } else if (action === 'nameChange') {
      // chagne category name
    }
  }

  console.log(jobDescription);
  if (resume === undefined || sources === undefined) {
    return <div>loading</div>;
  }
  return (
    <Grid container spacing={2} height="100dvh">
      <Grid xs={12} sx={{ height: '10%' }}>
        <ResumeNameTextFiled
          value={resume.name}
          onChange={debouncedHandleUpdateResumeName}
        />
      </Grid>
      <Grid xs={6} sx={{ height: '90%' }}>
        <Box height="90%">
          {view === 2 && (
            <ResumeEditor
              layout={resume.layout}
              resumeInfos={resume.data}
              onHideCategory={(category) => handleHideCategory(category)}
              onChange={(action, data) => handleResumeOnChange(action, data)}
              onMoveInfoUp={(infoId) => handleMoveInfoUp(infoId)}
              onMoveInfoDown={(infoId) => handleMoveInfoDown(infoId)}
              onDeleteInfo={(infoId) => handleDeleteInfo(infoId)}
              hiddenCategories={hiddenCategories}
            />
          )}

          {view === 1 && (
            <AllInfsWindow
              allInfos={sortedInfos}
              onAddToResume={(action, data) => handleResumeOnChange(action, data)}
            />
          )}
        </Box>
        <BottomNavBar
          view={view}
          handleChangeView={(viewIndex) => setView(viewIndex)}
        />
      </Grid>
      <Grid xs={6} sx={{ height: '90%' }}>
        <ResumeViewer
          handleAddSourceFromResume={(source) => handleAddSourceFromResume(source)}
          setResume={(newResume) => updateResume(newResume)}
          template={template}
          resume={resume}
          hiddenCategories={hiddenCategories}
        />
      </Grid>
    </Grid>
  );
}
