import * as React from 'react';
import Logo from 'components/Logo';
// import Logo from "Logo";
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import IconButton from '@mui/material/IconButton';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import Tooltip from '@mui/material/Tooltip';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

const btnList1 = [
  {
    icon: <HomeOutlinedIcon />,
    text: 'My Resume',
    url: '/App',
    description: 'View all your resumes',
  },
  {
    icon: <DeleteOutlineOutlinedIcon />,
    text: 'Trash',
    url: '/App/trash',
    description: 'View all your deleted resumes',
  },
  {
    icon: <StarBorderOutlinedIcon />,
    text: 'Premium',
    url: '/App/premium',
    description: 'subscription',
  },
  {
    icon: <PsychologyOutlinedIcon />,
    text: 'Create Cover Letter',
    url: '/App/ai',
    description: 'AI generated cover letter',
  },
  {
    icon: <PsychologyOutlinedIcon />,
    text: 'Parse Existing Resume',
    url: '/app/parser',
    description: 'Add you information',
  },
];

const btnList2 = [
  {
    icon: <ContactSupportOutlinedIcon />,
    text: 'Support',
    url: '/support',
    description: 'support',
  },
  {
    icon: <ExitToAppOutlinedIcon />,
    text: 'Log Out',
    url: '/logout',
    description: 'sign out',
  },
];

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export default function SideBar({ expand, setExpand }) {
  return (
    <Drawer variant="permanent" open={expand}>
      <DrawerHeader
        sx={
          expand
            ? { justifyContent: 'space-between' }
            : { justifyContent: 'space-around' }
        }
      >
        {expand && (
          <Link
            style={{ color: 'inherit', textDecoration: 'inherit' }}
            to="/App"
          >
            <Logo />
          </Link>
        )}
        {expand ? (
          <IconButton onClick={() => setExpand(!expand)}>
            <ChevronLeftIcon />
          </IconButton>
        ) : (
          <IconButton onClick={() => setExpand(!expand)}>
            <MenuOutlinedIcon />
          </IconButton>
        )}
      </DrawerHeader>

      <List sx={{ justifyItems: 'flex-end' }}>
        {btnList1.map((btnData) => (
          <Link
            key={`${btnData.text} btn`}
            style={{ color: 'inherit', textDecoration: 'inherit' }}
            to={btnData.url}
          >
            <ListItem disablePadding sx={{ display: 'block' }}>
              <Tooltip title={btnData.description} placement="right-start">
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: expand ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: expand ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    {btnData.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={btnData.text}
                    sx={{ opacity: expand ? 1 : 0 }}
                  />
                </ListItemButton>
              </Tooltip>
            </ListItem>
          </Link>
        ))}
      </List>

      <List sx={{ marginTop: 'auto', marginBottom: '10%' }}>
        {btnList2.map((btnData) => (
          <Link
            style={{ color: 'inherit', textDecoration: 'inherit' }}
            to={btnData.url}
            key={`${btnData.text} btn`}
          >
            <ListItem disablePadding sx={{ display: 'block' }}>
              <Tooltip title={btnData.description}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: expand ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: expand ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    {btnData.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={btnData.text}
                    sx={{ opacity: expand ? 1 : 0 }}
                  />
                </ListItemButton>
              </Tooltip>
            </ListItem>
          </Link>
        ))}
      </List>
    </Drawer>
  );
}
