import React from 'react';
import { useNavigate } from 'react-router-dom';
import { clearToken } from 'utils';

function LogoutPage() {
  const navigate = useNavigate();

  // Redirect to login page after signing out
  React.useEffect(() => {
    clearToken();
    navigate('/', { replace: true });
  }, [navigate]);

  return (
    <h1>Logging Out...</h1>
  );
}

export default LogoutPage;
