import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import TextField from '@mui/material/TextField';

export default function SearchBar({ content, handleSearchBarInput }) {
  function handleOnChange(e) {
    handleSearchBarInput(e.target.value);
  }

  const label = 'Search';

  return (
    <Toolbar>
      <Box sx={{ margin: 'auto', width: '50%' }}>
        <TextField
          onChange={(e) => handleOnChange(e)}
          id="outlined-basic"
          label={label}
          variant="outlined"
          fullWidth
          value={content}
        />
      </Box>
    </Toolbar>
  );
}
