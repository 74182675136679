import * as React from 'react';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AnimatedResumeCard from 'components/AnimatedResumeCard';
import EmptyTrashCanPage from './EmptyTrashCanPage';
import DeletionDialog from './DeletionDialog';
import SearchBar from '../SearchBar';
import DeletedResumeBtnGroup from './DeletedResumeBtnGroup';
import { restoreResumeById, getAllDeletedResumes, permanentlyDeleteResumesByIds } from '../../api';
// const darkTheme = createTheme({ palette: { mode: "dark" } });
const lightTheme = createTheme({ palette: { mode: 'light' } });
const fadeTime = 500;

/**
 * return a trash can logo if the trash can is empty,
 * else return a list of resume card
 * @returns trash can page component
 */
export default function TrashCan() {
  const [checkContain, setCheckContain] = React.useState('');

  const theme = lightTheme;
  // load deleted resume from local storage, add fadein animation value
  const [resumeList, setResumeList] = React.useState([]);
  const [removeAt, setRemoveAt] = React.useState(-1);
  const [dialogData, setDialogData] = React.useState({
    open: false,
    title: '',
    content: '',
    handleAgree: () => {},
    handleDisagree: () => {},
  });

  // load data
  React.useEffect(() => {
    async function _loadData() {
      // load deleted reumse
      const response = await getAllDeletedResumes();
      // error handle
      if (response.status !== 200) {
        console.log('error');
        return;
      }

      setResumeList(
        response.data.map((resume) => ({ ...resume, fadeIn: true })),
      );
    }
    _loadData();
  }, []);

  /**
   * trigger the fade out animation to play and mark resume as not deleted
   *
   */
  function handleRestore(id) {
    // trigger fade out animation
    const newResume = resumeList.map((resume, index) => {
      if (index === id) {
        return { ...resume, fadeIn: false };
      } return resume;
    });
    setResumeList(newResume);
    setRemoveAt(id);
    restoreResumeById(resumeList[id].id);
    // console.log("restore" + id);
  }
  function handleEmptyTrash() {
    permanentlyDeleteResumesByIds(resumeList.map((resume) => resume.id));
    setResumeList([]);
    // close dialog
    setDialogData((prev) => ({ ...prev, open: false }));
  }
  /**
 * trigger fade out animation to play
 * set removeAt to the id of the resume
 * mark the resume as not deleted
 * close the dialog
 * @param {*} id
 * @returns
 */
  function handleDeleteForever(id) {
    // TODO after remake the table remove from cloud
    const newResumeList = resumeList.map((resume, index) => {
      if (index === id) {
        return { ...resume, fadeIn: false };
      } return resume;
    });
    setResumeList(newResumeList);
    permanentlyDeleteResumesByIds([resumeList[id].id]);
    setRemoveAt(id);
    setDialogData((prev) => ({ ...prev, open: false }));
  }
  /**
   * handle the action after click delete button on resume card
   * display a dialog to confirm the action
   * @param {*} id
   */
  function handleClickDeleteResumeCard(id) {
    setDialogData({
      open: true,
      title: "Can't be restored",
      content: `Permenantly delete "${resumeList[id].title}"?`,
      handleAgree: () => handleDeleteForever(id),
      handleDisagree: () => setDialogData((prev) => ({ ...prev, open: false })),
    });
  }
  function handleClickEmptyTrash() {
    setDialogData({
      open: true,
      title: "Can't be restored",
      content: `Permanantly delete ${resumeList.length} resumes?`,
      handleAgree: () => handleEmptyTrash(),
      handleDisagree: () => setDialogData((prev) => ({ ...prev, open: false })),
    });
  }

  /**
   * use removeAt to remove from resumeList
   * @param {*} node
   * @param {*} done
   * @param {*} fadeIn
   */
  function handleTransitionEnd(node, done, fadeIn) {
    // handle appear
    if (fadeIn) {
      setTimeout(done(), fadeTime);
    } else {
      // handle restore and delete forever
      setTimeout(() => {
        const newResumeList = [
          ...resumeList.slice(0, removeAt),
          ...resumeList.slice(removeAt + 1),
        ];
        setResumeList(newResumeList);
        done();
      }, fadeTime);
    }
  }
  function handleSearchBarInput(newInput) {
    setCheckContain(newInput);
  }

  const dialog = (
    <DeletionDialog
      open={dialogData.open}
      title={dialogData.title}
      content={dialogData.content}
      handleAgree={dialogData.handleAgree}
      handleDisagree={dialogData.handleDisagree}
    />
  );

  // Filter
  // empty or undefine querykeyword becuase change querykeys will always rerender this from parent
  const filteredResumes = React.useMemo(() => {
    // empty and undefine return all resumes
    if (checkContain === '') return resumeList;
    return resumeList.filter((resume) => resume.name
      .toLowerCase()
      .includes(checkContain.toLowerCase()));
  }, [resumeList, checkContain]);
  const resumeCards = filteredResumes.map((resume, index) => {
    const btnGroup = DeletedResumeBtnGroup(
      index,
      handleRestore,
      handleClickDeleteResumeCard,
    );
    // );

    // resume in trash can should alwasy have id when added to resume galary
    return AnimatedResumeCard(
      { ...resume },
      resume.id,
      btnGroup,
      handleTransitionEnd,
    );
  });

  if (resumeList.length === 0) {
    return <EmptyTrashCanPage />;
  }
  return (
    <>
      <SearchBar
        content={checkContain}
        handleSearchBarInput={(newInput) => handleSearchBarInput(newInput)}
      />
      <ThemeProvider theme={theme}>
        {dialog}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Alert
                severity="warning"
                action={(
                  <Button
                    color="error"
                    size="small"
                    onClick={() => handleClickEmptyTrash()}
                  >
                    Empty Trash
                  </Button>
                  )}
              >
                Resumes in the trash are permanently deleted after 30 days.
              </Alert>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                p: 2,
                bgcolor: 'background.default',
                display: 'flex',
                flexWrap: 'wrap',
                rowGap: 2,
                columnGap: 2,
              }}
            >
              {resumeCards}
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
}
