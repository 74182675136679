import React from 'react';
import 'loading.css';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { checkLogin } from './api';

function LandingPage() {
  const navigate = useNavigate();

  // redirect to app or login page
  React.useEffect(() => {
    async function _checkLogin() {
      const logined = await checkLogin();
      if (logined) {
        navigate('/app', { replace: true });
      } else {
        navigate('/login', { replace: true });
      }
    }
    _checkLogin();
  }, [navigate]);
  // async function _upload_resume() {
  //   const resumes = JSON.parse(localStorage.getItem("allResumes"));
  //   console.log(resumes[0]);
  //   for (const resume of resumes) {
  //     console.log(resume);
  //     const name = resume.name;
  //     const layout = resume.category;
  //     const data = resume.data;
  //     const response = await axios.post(
  //       configData.SERVER_URL + "/api/v1/create_resume",
  //       {
  //         name: name,
  //         layout: layout,
  //         data: data,
  //       },
  //       {
  //         headers: {
  //           Authorization: "Bearer " + getToken(),
  //         },
  //       }
  //     );
  //   }
  // }
  //   _upload_resume();
  return (
    <Container maxWidth="sm">
      <Box sx={{
        display: 'flex', height: '100dvh', alignItems: 'center', justifyContent: 'center',
      }}
      >
        <div className="bouncing-dots">
          <div
            className="dot"
            style={{ animationDelay: '0s', backgroundColor: 'red' }}
          />
          <div
            className="dot"
            style={{ animationDelay: '0.1s', backgroundColor: 'green' }}
          />
          <div
            className="dot"
            style={{ animationDelay: '0.2s', backgroundColor: 'blue' }}
          />
          <div
            className="dot"
            style={{ animationDelay: '0.3s', backgroundColor: 'yellow' }}
          />
          <div
            className="dot"
            style={{ animationDelay: '0.4s', backgroundColor: 'orange' }}
          />
        </div>
      </Box>
    </Container>
  );
}

export default LandingPage;
