import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import { createResumeFromJobDescription } from '../api';
/**
 * A model let user to create a resume from a job description
 * @param {*} param0
 * @returns
 */
export default function NestedModal({ open, setOpen }) {
  const navigate = useNavigate();
  const jobDescription = 'job description here';
  const handleClose = () => {
    setOpen(false);
  };
  function handleConfirm() {
    async function _createResume() {
      const newResumeId = await createResumeFromJobDescription(jobDescription);
      setOpen(false);
      navigate(`/edit/${newResumeId}`);
    }
    _createResume();
  }
  function handleCancel() {
    setOpen(false);
  }

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
      <DialogTitle>
        <Grid container spacing={0}>
          <Grid item xs={4} sx={{ textAlign: 'left' }}>
            <Button variant="text" onClick={() => handleConfirm()}>
              Add
            </Button>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: 'center' }} />
          <Grid item xs={4} sx={{ textAlign: 'right' }}>
            <Button variant="text" onClick={() => handleCancel()}>
              Close
            </Button>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'left' }} />
        </Grid>
      </DialogTitle>
      <DialogContent>Job description here</DialogContent>
    </Dialog>
  );
}
