import ButtonGroup from '@mui/material/ButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import RestoreOutlinedIcon from '@mui/icons-material/RestoreOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import React from 'react';
/**
 * create a btn group for deleted resume, have two btn, restore and delete forever
 * @param {*} id the index of the resume in the list
 * @param {*} handleRestore, restore the resume
 * @param {*} handleDeleteForever, permanently delete the resume
 * @returns
 */
export default function DeletedResumeBtnGroup(
  id,
  handleRestore,
  handleDeleteForever,
) {
  function onClick(action) {
    if (action === 'restore') {
      handleRestore(id);
    } else if (action === 'deleteForever') {
      handleDeleteForever(id);
    }
  }
  return (
    <ButtonGroup
      size="small"
      variant="outlined"
      aria-label="outlined button group"
    >
      <Tooltip title="Delete forever">
        <IconButton
          aria-label="deleteForever"
          onClick={() => onClick('deleteForever')}
        >
          <DeleteForeverOutlinedIcon />
          {' '}
        </IconButton>
      </Tooltip>
      <Tooltip title="Restore">
        <IconButton aria-label="restore" onClick={() => onClick('restore')}>
          <RestoreOutlinedIcon />
          {' '}
        </IconButton>
      </Tooltip>
    </ButtonGroup>
  );
}
