import * as React from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import ContentContainer from './ContentContainer';
import BtnGroupContainer from './BtnGroupContainer';

const Card = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  width: 120,
  height: 160,
  cursor: 'pointer',
}));
/**
 * @param resumeId the id of the resume, used when open this resume
 * @param {*} {content: what to put in the title section,
 * @param btnGroup: put in the btn group section,
 * @param resumeId: the id of the resume this card represent,
 * @param animation: to play the slide up animation or not on btn group}
 * @param handleOpenResume action to perform when click the card body
 * @returns Card Component with title section and a btn group
 */
export default function ResumeCard({
  resumeId, content, btnGroup, resumeIndex, animation,
}) {
  const [beenHovered, setBeenHovered] = React.useState(false);
  const hightLightElevation = 5;
  const normalElevation = 1;

  function handleHover() {
    if (beenHovered === false) {
      setBeenHovered(true);
    }
  }
  function handleLeave() {
    if (beenHovered === true) {
      setBeenHovered(false);
    }
  }

  const wrappedContent = (
    <Box
      variant="body2"
      sx={{
        width: '100%', textAlign: 'center', lineHeight: '1.5', wordWrap: 'break-word',
      }}
    >
      {content}
    </Box>
  );

  return (
    <Card
      elevation={beenHovered ? hightLightElevation : normalElevation}
      onMouseOver={() => handleHover()}
      onMouseLeave={() => handleLeave()}
    >
      <ContentContainer
        content={wrappedContent}
        resumeIndex={resumeIndex}
        resumeId={resumeId}
      />
      {btnGroup && (
        <BtnGroupContainer
          displayBtnGroup={beenHovered}
          btnGroup={btnGroup}
          animation={animation}
        />
      )}
    </Card>
  );
}
