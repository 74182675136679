import React from 'react';
/**
 * generate a single userinfo component based on input
 * @param  userInfo a dictionary of one user info data
 * @param id: id of the entry
 * @returns a user info component
 */
export function UserInfoRenderer({ data }) {
  const {
    name, email, website, github, telephone,
  } = data;
  return (
    <div className="single-userInfo">
      <p>
        <span className="name">{name}</span>
      </p>
      <span className="info">
        <p>
          <a href={`mailto:${email}`}>
            <img
              src="https://simpleicons.org/icons/minutemailer.svg"
              alt="Mail"
            />
            {' '}
            {email}
          </a>
          <a href={`https://${github}`}>
            <img src="https://simpleicons.org/icons/github.svg" alt="GitHub" />
            {' '}
            {github}
          </a>
          <a href={`http://${website}`}>
            {' '}
            <img src="https://img.icons8.com/ios/50/null/web.png" alt="basic resume icon" />
            {website}
          </a>
          <span>
            {' '}
            {telephone}
          </span>
        </p>
      </span>
    </div>
  );
}

/**
 * generate a single education component based on input
 * @param {*} education a dictionary contain info
 * @returns education component
 */
export function EducationInfoRenderer({ data }) {
  return (
    <div className="single-educationInfo">
      <p>
        {data.school}
        ,
        {' '}
        {data.location}
        {' '}
      </p>
      <div className="education-detail">
        <h3 className="major">
          {' '}
          {data.major}
        </h3>
        <time className="time">{data.time}</time>
        {data.gpa && (
        <p className="gpa">
          {' '}
          GPA:
          {' '}
          {data.gpa}
        </p>
        )}
      </div>
      <p className="course">{data.course && `Courses: ${data.course}`}</p>
    </div>
  );
}

/**
 * generate a relaventExpirence component based on input
 * @param {*} id is used for naming key
 * @param {*} relativeExpirence data of relative expirence
 * @returns
 */
export function RelaventExpirenceRenderer({ data }) {
  const {
    title, year, contents, location,
  } = data;
  return (
    <div>
      <div className="relaventExpirence-info">
        <h3>{title}</h3>
        <p className="location">{location}</p>
        <time>{year}</time>
      </div>
      <div className="relaventExpirence-contents">
        <ul>
          {contents
            // eslint-disable-next-line react/no-array-index-key
            && contents.split('\\n').map((content, index) => <li key={index}>{content}</li>)}
        </ul>
      </div>
    </div>
  );
}

export function ProjectRenderer({ data }) {
  const { title, contents } = data;
  return (
    <div>
      <div className="project-info">
        {title && (
        <h3 className="project-title">
          {' '}
          {title}
          :
        </h3>
        )}

        {contents
          && contents.split('\\n').map((content, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <p className="project-contents" key={index}>
              {content}
            </p>
          ))}
      </div>
    </div>
  );
}

export function SkillRenderer({ data }) {
  const { skillname, contents } = data;
  return (
    <div className="section-skill">
      <span className="skill-name">
        {skillname}
        :
      </span>
      <span className="skill-content">{contents}</span>
    </div>
  );
}
