import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import RenderedResume from 'components/ResumeRenderer';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ScaleSlider from 'components/ScaleSlider';
import { useReactToPrint } from 'react-to-print';
import IconButton from '@mui/material/IconButton';
import { calculateScale } from 'utils';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { updateResumeById } from '../api';
import 'default.css';
/**
 * View the rendered resume also provide the functionality
 * to download and scale the resume
 *
 * @param {*} param0
 * @returns
 */
export default function ResumeViewer({
  resume,
  setResume,
  handleAddSourceFromResume,
  hiddenCategories,
}) {
  // console.log(resume);
  const printRef = React.useRef();
  const [scale, setScale] = React.useState(0.7);
  const [autoScale, setAutoScale] = React.useState(false);
  const ResumePage = styled('div')({
    border: '1px solid black',
    height: '28cm',
    width: '22cm',
    transform: `scale(${scale}, ${scale})`,
    transformOrigin: '0 0',
  });
  async function _updateResume(id, newResume) {
    const res = await updateResumeById(id, newResume);
    if (res.status !== 200) {
      console.log('error');
      return;
    }
    setResume(res.data);
  }
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  function handleScale() {
    setScale(scale);
  }

  const setResumeData = (data) => {
    _updateResume(resume.id, { ...resume, data });
  };

  function handleToggleAutoScale() {
    if (!autoScale) {
      setScale(calculateScale('resumePage', 'viewerContainer'));
    }
    setAutoScale((prev) => !prev);
  }
  React.useEffect(() => {
    // scale resume page based on the size of the container when DOM is ready
    if (resume !== undefined) {
      const setScaleTo = calculateScale('resumePage', 'viewerContainer');
      setScale(setScaleTo);
    }
  }, [resume]);

  return (
    <Box width="1" height="1" display="flex" flexDirection="column">
      <Box
        id="viewerContainer"
        sx={{ width: '100%', height: '95%', overflow: 'hidden' }}
      >
        <ResumePage id="resumePage">
          <RenderedResume
            ref={printRef}
            downloadName={resume.name}
            setResumeData={setResumeData}
            layout={resume.layout}
            resumeData={resume.data}
            handleAddSourceFromResume={handleAddSourceFromResume}
            hiddenCategories={hiddenCategories}
          />
        </ResumePage>
      </Box>
      <Stack direction="row" spacing={1}>
        <ScaleSlider handleChange={() => handleScale()} />
        <FormControlLabel
          control={(
            <Checkbox
              checked={autoScale}
              onChange={() => handleToggleAutoScale()}
              name="autoScale"
            />
          )}
          label="Auto Scale"
        />

        <IconButton
          aria-label="fingerprint"
          color="secondary"
          onClick={handlePrint}
        >
          <FileDownloadOutlinedIcon />
        </IconButton>
      </Stack>
    </Box>
  );
}
