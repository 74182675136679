import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Validator from 'validatorjs';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Copyright from 'components/Copyright';
import Alert from '@mui/material/Alert';
import { setToken } from '../utils';
import { signIn, register } from '../api';

const defaultTheme = createTheme();
/**
 * page handle user login or register
 * @returns login page component
 *
 * redirect to / page if login or register success
 * for login : if save on this laptop, token will save to cookie without expiration,
 * otherwise saved to session storage.
 * for register: token will save to session storage.
 */
export default function SignInSignUp() {
  const navigate = useNavigate();
  const [authErrror, setAuthError] = React.useState({
    error: false,
    message: '',
  });
  const [toggleRegister, setToggleRegister] = React.useState(false);
  const [logined, setLogined] = React.useState(false);
  const [authForm, setAuthForm] = React.useState({
    email: '',
    password: '',
    username: '',
    rePassword: '',
    save: 'notSave',
  });
  function handleChange(event) {
    setAuthForm({
      ...authForm,
      [event.target.name]: event.target.value,
    });
  }
  // different rule for login and register
  const validationRules = toggleRegister
    ? {
      email: 'email',
      password: 'min:8',
      username: 'alpha_dash',
      rePassword: 'same:password',
    }
    : {
      email: 'email',
    };

  // validate form
  const validation = new Validator(authForm, validationRules);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    // do nothing until pass validation
    if (validation.fails()) return;

    // login
    if (!toggleRegister) {
      const response = await signIn({
        email: data.get('email'),
        password: data.get('password'),
      });

      if (response.status === 200) {
        setToken(response.data.access_token, data.get('save') === 'save');
        setLogined(true);
      } else {
        setAuthError({
          error: true,
          message: 'Wrong email or password',
        });
      }
    } else {
      // register
      const response = await register({
        email: data.get('email'),
        password: data.get('password'),
        username: data.get('username'),
      });

      if (response.status === 201) {
        setToken(response.data.access_token, false);
        setLogined(true);
      } else {
        setAuthError({
          error: true,
          message: 'Email already exist',
        });
      }
    }
  };
  // login or register redirect
  React.useEffect(() => {
    if (logined) {
      navigate('/');
    }
  }, [logined, navigate]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {toggleRegister ? 'Register' : 'Sign in'}
          </Typography>

          {authErrror.error && (
            <Alert severity="error" sx={{ width: 1 }}>
              {authErrror.message}
            </Alert>
          )}

          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate={false}
            onChange={(e) => handleChange(e)}
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              error={validation.fails('email')}
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              helperText={validation.errors.first('email')}
            />
            {toggleRegister && (
              <TextField
                margin="normal"
                required
                fullWidth
                error={validation.fails('username')}
                id="username"
                label="User Name"
                name="username"
                helperText={validation.errors.first('username')}
              />
            )}
            <TextField
              margin="normal"
              required
              fullWidth
              error={validation.fails('password')}
              name="password"
              label="Password"
              type="password"
              id="password"
              helperText={validation.errors.first('password')}
              autoComplete="current-password"
            />

            {toggleRegister && (
              <TextField
                margin="normal"
                required
                fullWidth
                name="rePassword"
                label="Confirm Password"
                type="password"
                error={validation.fails('rePassword')}
                helperText={validation.errors.first('rePassword')}
                id="rePassword"
                autoComplete="current-password"
              />
            )}
            {!toggleRegister && (
              <FormControlLabel
                name="save"
                control={(
                  <Checkbox
                    value={authForm.save === 'save' ? 'notSave' : 'save'}
                    color="primary"
                  />
                )}
                label="Save on this laptop"
              />
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {toggleRegister ? 'Sign Up' : 'Sign In'}
            </Button>
            <Grid container>
              <Grid item xs>
                {!toggleRegister && (
                  <Link href="/#" variant="body2">
                    Forgot password?
                  </Link>
                )}
              </Grid>
              <Grid item>
                <Link
                  href="/#"
                  variant="body2"
                  onClick={(e) => {
                    e.preventDefault();
                    setToggleRegister((prev) => !prev);
                    setAuthError({ error: false, message: '' });
                  }}
                >
                  {toggleRegister
                    ? 'Already have an account? Sign in'
                    : "Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
