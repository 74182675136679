import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import LogoutPage from 'logoutPage/page';
import LoginPage from 'signInPage/page';
import './index.css';
import ResumeGalary from 'appPage/ResumeGalary';
import TrashCan from 'appPage/trashCan/TrashCan';
import App from './AppRoot';
import EditorPage from './editorPage/page';
import LandingPage from './LandingPage';

const router = createBrowserRouter([
  {
    path: '/app',
    element: <App />,
    children: [
      {
        path: '/app',
        element: <ResumeGalary />,
      },
      {
        path: 'trash',
        element: <TrashCan />,
      },
      {
        path: 'edit/:resumeId',
        element: <EditorPage />,
      },
    ],
  },
  {
    path: '/',
    element: <LandingPage />,
  },
  {
    path: '/logout',
    element: <LogoutPage />,
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
]);

// TODO remove this code
// add template to local storage
function _addTemplate() {
  const templates = {
    0: [
      [
        'user_information',
        [
          ['name', '45%'],
          ['email', '45%'],
          ['github', '45%'],
          ['telephone', '45%'],
          ['website', '92%'],
        ],
      ],
      [
        'education',
        [
          ['school', '70%'],
          ['gpa', '20%'],
          ['major', '45%'],
          ['time', '45%'],
          ['location', '92%'],
          ['course', '92%'],
        ],
      ],
      [
        'skill',
        [
          ['skillname', '92%'],
          ['contents', '92%'],
        ],
      ],
      [
        'relevant_experience',
        [
          ['title', '92%'],
          ['year', '45%'],
          ['location', '45%'],
          ['contents', '92%'],
        ],
      ],
      [
        'extracurricular',
        [
          ['title', '92%'],
          ['year', '45%'],
          ['location', '45%'],
          ['contents', '92%'],
        ],
      ],
      [
        'project',
        [
          ['title', '92%'],
          ['contents', '92%'],
        ],
      ],
    ],
  };
  localStorage.setItem('templates', JSON.stringify(templates));
}
_addTemplate();
ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
