import React from 'react';
import { styled } from '@mui/material/styles';
import { toAllCap } from 'utils';
import {
  UserInfoRenderer,
  EducationInfoRenderer,
  SkillRenderer,
  RelaventExpirenceRenderer,
  ProjectRenderer,
} from '../resume/EntryRenders';

const ResumeTemplate = styled('div')({
  display: 'block',
  width: '700px',
  margin: '0 auto',
  padding: 0,
  frontFamily: 'Roboto',
  lineHeight: 1,
  // backgroundColor:"blue",
});

// TODO fixed size, added a page background
/**
 * render a resume based on template with a download fab
 * @param {*} resumeEntries: a dictioanry of resume entries {category: [entry1, entry2]}
 */
const Resume = React.forwardRef((props, ref) => {
  const {
    downloadName,
    resumeData,
    layout,
    hiddenCategories,
  } = props;

  const sourceByCategory = React.useMemo(() => {
    const newSourceByCategory = {};
    resumeData.forEach((entry) => {
      if (newSourceByCategory[entry.category] === undefined) {
        newSourceByCategory[entry.category] = [];
      }
      newSourceByCategory[entry.category].push(entry);
    });
    return newSourceByCategory;
  }, [resumeData]);

  function createInteractiveWraper(category) {
    const categoryToRender = {
      education: EducationInfoRenderer,
      skill: SkillRenderer,
      relevant_experience: RelaventExpirenceRenderer,
      project: ProjectRenderer,
      extracurricular: ProjectRenderer,
    };
    const hidden = hiddenCategories[category] || false;
    if (hidden) {
      return null;
    }
    if (category === 'user_information') {
      return sourceByCategory.user_information?.map((source) => (
        <UserInfoRenderer data={source.data} />
      ));
    }
    const Renderer = categoryToRender[category];
    return (
      <>
        <h2 id={category}>{toAllCap(category)}</h2>
        {sourceByCategory[category]?.map((source) => (
          <Renderer data={source.data} />
        ))}
      </>
    );
  }
  return (
    <ResumeTemplate ref={ref} className="resume_tamplate">
      <title>
        {' '}
        {downloadName}
      </title>
      {layout.map((t) => createInteractiveWraper(t[0]))}
    </ResumeTemplate>
  );
});

export default Resume;
