import * as React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CategoryActionBtn from 'components/CategoryActionBtn';
import InfoActionBtn from 'components/InfoActionBtn';
import Form from 'components/UserInfoForm';

function ResumeEditor({
  layout,
  resumeInfos,
  onChange,
  onMoveInfoDown,
  onMoveInfoUp,
  onDeleteInfo,
  onHideCategory,
  hiddenCategories,
}) {
  const infoUnderCategory = {};

  resumeInfos.forEach((info) => {
    const formForCategory = layout.find((t) => t[0] === info.category);
    function handleFormChange(id, e) {
      const fieldName = e.target.id;
      const content = e.target.value;
      onChange('edit', { id, fieldName, content });
    }
    const infoForm = (
      <Form
        key={info.id}
        template={formForCategory[1]}
        onChange={(e) => handleFormChange(info.id, e)}
        data={info.data}
        id={info.id}
      />
    );
    // TODO: add isFirst and isLast
    const isFirst = resumeInfos.indexOf(info) === 0;
    const isLast = resumeInfos.indexOf(info) === resumeInfos.length - 1;
    const interactiveWraper = (
      <InfoActionBtn
        infoForm={infoForm}
        onMoveInfoUp={() => onMoveInfoUp(info.id)}
        onMoveInfoDown={() => onMoveInfoDown(info.id)}
        onDeleteInfo={() => onDeleteInfo(info.id)}
        isFirst={isFirst}
        isLast={isLast}
      />
    );

    if (info.category in infoUnderCategory) {
      infoUnderCategory[info.category].push(interactiveWraper);
    } else {
      infoUnderCategory[info.category] = [interactiveWraper];
    }
  });

  function hanldeChangeLayout(action, category) {
    onChange(action, category);
  }

  return (
    <Stack height={1} width={1} overflow="auto">
      {layout.map((t) => {
        const category = t[0];
        const hidden = hiddenCategories[category] || false;
        return (
          <Box key={category}>
            <Box position="sticky" top="0" zIndex="100" bgcolor="white">
              <CategoryActionBtn
                category={category}
                isFirst={false}
                isLast={false}
                onChange={(action) => hanldeChangeLayout(action, category)}
                onHideCategory={() => onHideCategory(category)}
                hidden={hidden}
              />
            </Box>
            {!hidden && infoUnderCategory[category]}
          </Box>
        );
      })}
    </Stack>
  );
}

export default ResumeEditor;
