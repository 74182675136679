import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export default function CategoryActionBtn({
  category,
  onChange,
  onHideCategory,
  isFirst,
  isLast,
  hidden,
}) {
  function handleMoveUp() {
    onChange('moveup', category);
  }
  function handleMoveDown() {
    // pass
    onChange('movedown', category);
  }
  function handleNameChange() {
    // pass
    onChange('nameChange', category);
  }

  return (
    <Box
      display="flex"
      width="1"
      justifyContent="space-between"
      alignItems="start"
    >
      <Typography variant="h5">
        <TextField
          onChange={() => handleNameChange()}
          value={category}
          variant="standard"
          InputProps={{
            readOnly: false,
            disableUnderline: true,
            style: {
              padding: 0,
              border: 'none',
              background: 'transparent',
              fontFamily: 'inherit',
              fontSize: 'inherit',
              color: 'inherit',
            },
          }}
        />
      </Typography>
      <ButtonGroup>
        {!isFirst && (
          <Tooltip title="move this category up">
            <IconButton aria-label="move up" onClick={() => handleMoveUp()}>
              <ArrowUpwardIcon />
            </IconButton>
          </Tooltip>
        )}
        {!isLast && (
          <Tooltip title="move this category down">
            <IconButton aria-label="move down" onClick={() => handleMoveDown()}>
              <ArrowDownwardIcon />
            </IconButton>
          </Tooltip>
        )}

        {hidden ? (
          <Tooltip title="show this category">
            <IconButton aria-label="show" onClick={onHideCategory}>
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="hide this category">
            <IconButton aria-label="hide" onClick={onHideCategory}>
              <VisibilityOffIcon />
            </IconButton>
          </Tooltip>
        )}
      </ButtonGroup>
    </Box>
  );
}
