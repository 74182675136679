import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import ResumeCard from 'appPage/resumeCard/ResumeCard';
import React from 'react';
/**
 * factory method to create a ResumeCard component with Fade animation
 * resume card has two part, a card title and button group
 * @param {} resume {id, title, lastEdit, fadeIn},
 * fadeIn is a boolean to decide whether to show the animation
 * @param {*} id the index of the resume in the list
 * @param {*} btnGroup btn group
 * @param {*} handleTransitionEnd handle the animation end event
 * @returns <Fade> with <ResumeCard> component
 */
export default function AnimatedResumeCard(
  { name, id: key, fadeIn },
  id,
  btnGroup,
  handleTransitionEnd,
) {
  return (
    <Fade
      in={fadeIn}
      key={key}
      addEndListener={(node, done) => handleTransitionEnd(node, done, fadeIn)}
    >
      <Box sx={{ height: '167px' }}>
        <ResumeCard
          content={name}
          resumeId={key}
          resumeIndex={id}
          btnGroup={btnGroup}
          animation
        />
      </Box>
    </Fade>
  );
}
