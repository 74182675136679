import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import { AccordionDetails } from '@mui/material';
import FormWraper from 'components/UserFormInteractiveWrapper';
import IconButton from '@mui/material/IconButton';
import BtnGrup from 'components/CARBtnGroup';
import Tooltip from '@mui/material/Tooltip';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { createEmptyInfoData } from 'utils';
import { addNewInfo, deleteSourceByIds } from 'api';
/**
 * Component to display list of user information
 *
 * @param {*} param0
 * @returns
 */
export default function AllInfsWindow({ allInfos, onAddToResume }) {
  const [infos, setInfos] = React.useState(allInfos);
  const [notExpand, setNotExpand] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState([]);
  const templates = JSON.parse(localStorage.getItem('templates'));
  const template = templates[0];
  // const categoryAccordions = {};

  function handleChangeOnInfo(data) {
    // check if all category goging to be selected, update selectedCategroy accrodionly
    const { category } = data;
    const newInfos = infos.map((info) => {
      if (info.id === data.id) {
        return data;
      }
      return info;
    });
    const infoUnderCategory = newInfos.filter(
      (info) => info.category === category,
    );
    const allSelected = infoUnderCategory.every((entry) => entry.selected);

    // if not all selected and category is selected, unselect category
    // if all selected and category is not selected, select category
    if (allSelected && !selectedCategory.includes(category)) {
      setSelectedCategory((prev) => [...prev, category]);
    } else if (!allSelected && selectedCategory.includes(category)) {
      setSelectedCategory((prev) => prev.filter((item) => item !== category));
    }

    setInfos(newInfos);
  }

  const categoryAccordions = React.useMemo(() => {
    // sort infos first
    const sortedInfos = infos.sort((a, b) => b.last_edit_date - a.last_edit_date);
    // create accordions
    const accordions = {};
    sortedInfos.forEach((info) => {
      const interactiveWraper = (
        <Box marginY={1} key={info.id}>
          <FormWraper
            formData={{
              ...info,
              template: template.find((t) => t[0] === info.category)[1],
            }}
            onChange={(data) => handleChangeOnInfo(data)}
          />
        </Box>
      );
      if (info.category in accordions) {
        accordions[info.category].push(interactiveWraper);
      } else {
        accordions[info.category] = [interactiveWraper];
      }
    });
    return accordions;
  }, [infos]);

  function handleAddToResume() {
    const selected = infos.filter((info) => info.selected);
    onAddToResume('add', selected);
    // unset selected
    setInfos((prev) => prev.map((info) => ({
      ...info,
      selected: false,
    })));
  }
  async function handleDeleteInfos() {
    const selected = infos.filter((info) => info.selected);
    const ids = selected.map((info) => info.id);
    await deleteSourceByIds(ids);
    setInfos((prev) => prev.filter((info) => !ids.includes(info.id)));
  }

  function handleClickOnCARBtnGroup(action) {
    if (action === 'add') {
      console.log('add');
      handleAddToResume();
    } else if (action === 'delete') {
      console.log('delete');
      handleDeleteInfos();
    }
  }

  async function handleAddNewInfo(category) {
    const categoryTemplate = template.find((t) => t[0] === category)[1];
    const newData = createEmptyInfoData(categoryTemplate);
    const newEntry = {
      data: newData,
      category,
      name: `new ${category}`,
    };

    const newInfo = await addNewInfo(newEntry);
    setInfos((prev) => [
      ...prev,
      {
        ...newInfo.data,
        template,
        selected: false,
      },
    ]);
  }
  function handleExpand(name) {
    setNotExpand((prev) => {
      if (prev.includes(name)) {
        return prev.filter((item) => item !== name);
      }
      return [...prev, name];
    });
  }

  function handleSelectCategory(category) {
    if (selectedCategory.includes(category)) {
      // untoggle all selected info under category
      setInfos((prev) => prev.map((item) => {
        if (item.category === category) {
          return {
            ...item,
            selected: false,
          };
        }
        return item;
      }));
      setSelectedCategory((prev) => prev.filter((item) => item !== category));
    } else {
      // toggle all selected info under category
      setInfos((prev) => prev.map((item) => {
        if (item.category === category) {
          return {
            ...item,
            selected: true,
          };
        }
        return item;
      }));
      setSelectedCategory((prev) => [...prev, category]);
    }
  }
  function handleAccordionAction(e, action, id) {
    e.stopPropagation();
    e.preventDefault();
    if (action === 'accordion') {
      handleExpand(id);
    } else if (action === 'checkbox') {
      handleSelectCategory(id);
    } else if (action === 'addNew') {
      handleAddNewInfo(id);
    }
  }
  function createAccordions() {
    const accordions = Object.keys(categoryAccordions).map((category) => (
      <Accordion
        key={`${category}-panel`}
        expanded={!notExpand.includes(`${category}-panel`)}
        onClick={(e) => handleAccordionAction(e, 'accordion', `${category}-panel`)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            borderRadius: '10px',
            position: 'sticky',
            top: '0',
            backgroundColor: '#D3D3D3',
            zIndex: '100',
          }}
        >
          <Box
            display="flex"
            alignContent="center"
            justifyContent="space-between"
            alignItems="center"
            width="1"
          >
            <Typography sx={{ flexGrow: 1 }}>{category}</Typography>
            <Tooltip title={`Create a new ${category}`}>
              <IconButton
                onClick={(e) => handleAccordionAction(e, 'addNew', category)}
                aria-label={`Create a new ${category}`}
              >
                <AddCircleIcon />
              </IconButton>
            </Tooltip>
            <Checkbox
              onClick={(e) => handleAccordionAction(e, 'checkbox', category)}
              checked={selectedCategory.includes(category)}
              inputProps={{ 'aria-label': 'select all category' }}
            />
          </Box>
        </AccordionSummary>
        <AccordionDetails onClick={(e) => e.stopPropagation()}>
          {categoryAccordions[category]}
        </AccordionDetails>
      </Accordion>
    ));
    return accordions;
  }

  const selectedInfos = infos.filter((info) => info.selected);
  const accordions = createAccordions(categoryAccordions);

  //   const infoUnderCategory =
  // const handleCARBtnGroupClick = React.useCallback(() => {
  //   handleClickOnCARBtnGroup();
  // }, [handleClickOnCARBtnGroup]);

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      {selectedInfos.length > 0 && (
        <BtnGrup
          count={selectedInfos.length}
          onClick={(action) => handleClickOnCARBtnGroup(action)}
        />
      )}
      <Box width={1} height={1} overflow="scroll">
        {accordions}
      </Box>
    </Box>
  );
}
