import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import InventoryIcon from '@mui/icons-material/Inventory';
import EditIcon from '@mui/icons-material/Edit';
import NoteAddIcon from '@mui/icons-material/NoteAdd';

export default function BottomNavBar({ view, handleChangeView }) {
  function handleClick(e, newValue) {
    handleChangeView(newValue);
  }

  return (
    <Box sx={{ width: 1 }}>
      <BottomNavigation
        showLabels
        value={view}
        onChange={(e, newValue) => handleClick(e, newValue)}
      >
        <BottomNavigationAction label="Check Qualification" icon={<AnalyticsIcon />} />
        <BottomNavigationAction label="All Informations" icon={<InventoryIcon />} />
        <BottomNavigationAction label="Edite Resume" icon={<EditIcon />} />
        <BottomNavigationAction label="Template" icon={<FormatListBulletedOutlinedIcon />} />
        <BottomNavigationAction label="Cover Letter" icon={<NoteAddIcon />} />
      </BottomNavigation>
    </Box>
  );
}
