import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import trashCanPng from '../../assets/empty-trash-can.png';

export default function EmptyTrashCanPage() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img src={trashCanPng} alt="empty trash can" />
      <Typography variant="h4" component="h1">
        Trash is empty
      </Typography>
      <Typography variant="p" component="p">
        deleted resume will appear here for 30 days
      </Typography>
    </Box>
  );
}
