import { nanoid } from 'nanoid';
import axios from 'axios';
import { getToken } from './utils';

const RESUMEDB = 'allResumes';
// const SOURCEDB = "sources";
const TEMPLATEDB = 'templates';
// const DRAFTDB = "drafts";
const JOBDESCRIPTIONDB = 'jobDescription';

/**
 * update resume source
 * @param {*} newSource {id,data:dictonary,name}
 * @returns updated resume source
 */
export async function updateResumeSource(newSource) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/update_source_by_id`,
      {
        id: newSource.id,
        data: newSource.data,
        name: newSource.name,
      },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      },
    );
    return response;
  } catch (e) {
    return e;
  }
}
/**
 * save new resume entry to local storage and return the new entry
 * @param {*} sourceData {category, name, data}
 * @returns the new entry {id : int generate by database
                          name : string, don’t have to be unique
                          category: string
                          data: [list of string]
                          tag: [list of string]
                          last_edit_date: int unix time}
 */
export async function addNewInfo(sourceData) {
  const newEntry = {
    name: sourceData.name || `New ${sourceData.category}`,
    category: sourceData.category,
    data: sourceData.data,
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/add_new_source`,
      newEntry,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      },
    );

    return response;
  } catch (e) {
    return e;
  }
}

/**
 * load all resume entry from db
 * @return a dictioanry {category: [entry]}
 */
export async function getAllSources() {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/get_all_sources`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      },
    );
    return response;
  } catch (e) {
    return e;
  }
}
/**
 * get template by id
 * @param {*} id
 */
export async function getTemplateById(id) {
  const allTemplate = JSON.parse(localStorage.getItem(TEMPLATEDB)) || {};
  if (id in allTemplate) {
    return allTemplate[id];
  }
  return null;
}
/**
 * get all resume marked as deleted
 * @returns [resume]
 */
export async function getAllDeletedResumes() {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/get_all_deleted_resumes`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      },
    );

    return response;
  } catch (error) {
    return error;
  }
}

/**
 * permanetly delete resumes with ids
 */
export async function permanentlyDeleteResumesByIds(ids) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/permanently_delete_resume_by_ids`,
      {
        ids,
      },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      },
    );
    return response;
  } catch (e) {
    return e;
  }
}

/**
 * get all resume that is not been marked as deleted
 * @returns [{id, name, last_edit_date}]
 */
export async function getAllResumes() {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/get_all_resumes_names_and_ids`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      },
    );
    if (response.status === 200) {
      return response;
    }
    return response;
  } catch (error) {
    return error.status;
  }
}

/**
 * resume a resume with this id
 * @param {*} id id of the resume
 * @returns resume
 */
export async function getResumeById(id) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/get_resume_by_id`,
      {
        params: {
          id,
        },
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      },
    );
    return response;
  } catch (error) {
    return error;
  }
}

/**
 * update resume by id in local storeage
 * @param {*} id id of that resume
 * @param {*} newResume {category:[list of category],
 * entry_for_category:[list of source data], name: string, last_edit_date: int unix time}
 * return the updated resume
 */
export function updateResumeById(id, newResume) {
  try {
    const response = axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/update_resume`,
      newResume,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      },
    );
    return response;
  } catch (e) {
    return e;
  }
}

/**
 * mark resume with this id as deleted
 * @param {*} id
 */
export async function deleteResumeWithId(id) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/delete_resume_by_id`,
      {
        id,
      },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      },
    );
    return response;
  } catch (error) {
    return error;
  }
}

/**
 * mark resume as not deleted, and update the last edit date to current time
 * @param {*} id the resume to restore
 */
export async function restoreResumeById(id) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/restore_resume_by_id`,
      {
        id,
      },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      },
    );
    return response;
  } catch (e) {
    return e;
  }
}

/**
 * create or duplicate an existing resume
 * @param {*} data string job description
 * @return  {id: int, name: string, last_edit_date: int unix time} of new resume
 */
export async function createNewResume(duplicateThisId) {
  // duplicate
  if (duplicateThisId !== undefined) {
    // get all resume's name
    let response = await getAllResumes();
    const allResumes = response.data;

    // get duplicate resume
    response = await getResumeById(duplicateThisId);

    // create a new resume base on that
    const newResume = {
      data: response.data.data,
      name: `${response.data.name} copy`,
      layout: response.data.layout,
    };
    // handle name duplication
    while (
      Object.values(allResumes).some((item) => item.name === newResume.name)
    ) {
      newResume.name += ' copy';
    }
    // upload
    response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/create_resume`,
      newResume,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      },
    );
    return response;
  }
  // create empty resume

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/create_resume`,
      {
        name: 'New Resume',
      },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      },
    );
    return response;
  } catch (e) {
    return e;
  }
}

/**
 * create a new job description or update current job description
 * job description {id, resume id, name, content}
 * @param {*} id resume id
 * @param {*} newJobDescription {name, content}
 */
export async function updateJobDescriptionByResumeId(id, newJobDescription) {
  const allJobDescription = JSON.parse(localStorage.getItem(JOBDESCRIPTIONDB)) || {};
  allJobDescription[id] = newJobDescription;
  localStorage.setItem(JOBDESCRIPTIONDB, JSON.stringify(allJobDescription));
  return allJobDescription[id];
}
/**
 * get job description by resume id, return undefined if no job description with resumeID
 * @param {*} id resume id
 * @returns {name,content} or undefined
 */
export async function getJobDecriptionByResumeId(id) {
  const allJobDescription = JSON.parse(localStorage.getItem(JOBDESCRIPTIONDB)) || {};
  return allJobDescription[id];
}
/**
 * remove resume source with this id
 * @param {*} id
 * @returns
 */
export async function deleteSourceByIds(ids) {
  const response = await axios.post(
    `${process.env.REACT_APP_SERVER_URL}/api/v1/delete_source_by_ids`,
    {
      ids,
    },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    },
  );
  return response;
}
/**
 * Create a resume from job description
 * return the id to allow redirect to the resume
 * @param {*} jobDescription
 */
export async function createResumeFromJobDescription(jobDescription) {
  // TODO: right now just create a new resume with jobDescription title as the resume name
  const { title, content } = jobDescription;

  // TODO: temporary solution for eslint
  console.log(content);
  const allResumes = JSON.parse(localStorage.getItem(RESUMEDB)) || [];
  const newResume = {
    id: nanoid(),
    name: title,
    category: [],
    data: [],
    deleted: false,
    last_edit_date: Math.floor(Date.now() / 1000),
  };
  allResumes.push(newResume);
  localStorage.setItem(RESUMEDB, JSON.stringify(allResumes));
  // update job description
  updateJobDescriptionByResumeId(newResume.id, jobDescription);
  return newResume.id;
}

export async function checkLogin() {
  const token = getToken();
  try {
    const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/verify`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response === 200) {
      return true;
    }
    return true;
  } catch {
    return false;
  }
}
/**
 * return access_token
 * @param {*} data
 */
export async function signIn(data) {
  const { email } = data;
  const { password } = data;
  console.log('server add is');
  console.log(process.env);
  try {
    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v1/login`, {
      email,
      password,
    });
    return response;
  } catch (error) {
    return error.response;
  }
  // pass
}

/**
 * return access_token
 * @param {*} data
 */
export async function register(data) {
  const { email } = data;
  const { password } = data;
  const { username } = data;
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/register`,
      {
        email,
        password,
        username,
      },
    );
    return response;
  } catch (error) {
    return error.response;
  }
  // pass
}
