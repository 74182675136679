import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import React from 'react';

export default function BtnGroupContainer({
  displayBtnGroup,
  animation,
  btnGroup,
}) {
  const containerRef = React.useRef(null);
  return (
    <Box
      ref={containerRef}
      sx={{ overflow: 'hidden', height: '30%', width: '100%' }}
    >
      {animation ? (
        <Slide
          direction="up"
          in={displayBtnGroup}
          container={containerRef.current}
        >
          {btnGroup}
        </Slide>
      ) : (
        btnGroup
      )}
    </Box>
  );
}
