import * as React from 'react';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
/**
 * A component for editing resume name
 * @param {*} param0
 * @returns
 */
export default function ResumenameTextField({ value, onChange }) {
  const [resumeName, setResumeName] = React.useState(value);
  function handleOnChange(e) {
    setResumeName(e.target.value);
    onChange(e.target.value);
  }
  return (
    <Typography variant="h4">
      <TextField
        fullWidth
        onChange={(e) => handleOnChange(e)}
        variant="standard"
        value={resumeName}
        InputProps={{
          readOnly: false,
          disableUnderline: true,
          style: {
            padding: 0,
            border: 'none',
            background: 'transparent',
            fontFamily: 'inherit',
            fontSize: 'inherit',
            color: 'inherit',
          },
          inputProps: {
            style: {
              textAlign: 'center', // Set text alignment to center
            },
          },
        }}
      />
    </Typography>
  );
}
