import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import React from 'react';

export default function Logo() {
  const icon = (
    <Box
      sx={{
        margin: 'auto',
      }}
    >
      <img
        width="100"
        height="100"
        src="https://img.icons8.com/clouds/100/resume.png"
        alt="basic_resume_icon"
      />
    </Box>
  );
  // eslint-disable-next-line react/no-children-prop
  return <Toolbar children={icon} />;
}
