import Cookies from 'js-cookie';

export async function postJsonToEndPointWithoutCredential(url, data) {
  const headers = { 'Content-Type': 'application/json' };
  const response = await fetch(process.env.REACT_APP_REACT_APP_SERVER_URL + url, {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
  });
  const resonseData = await response.json();
  return resonseData;
}
/**
 * store token to session storage or cookie
 * @param {*} token acces token
 * @param {*} save if true store to cookie, else store to session storage
 */
export function setToken(token, save) {
  // if not save store to session storage
  if (!save) {
    sessionStorage.setItem('accessToken', token);
  } else {
    Cookies.set('accessToken', token);
  }
}
export async function postJsonToEndPointWithCredential(url, data) {
  const headers = {
    'Content-Type': 'application/json',
    // TODO change getting accessToken from cookie
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  };
  const response = await fetch(process.env.REACT_APP_SERVER_URL + url, {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
  });
  const resonseData = await response.json();
  return resonseData;
}
export function flatADictionary(dictionary) {
  return Object.keys(dictionary).reduce((listFromDictionary, key) => {
    if (Object.prototype.hasOwnProperty.call(dictionary, key)) {
      return listFromDictionary.concat(Object.values(dictionary[key]));
    }
    return listFromDictionary;
  }, []);
}
export function toTitle(input) {
  const splitString = input.replace('_', ' ');
  return splitString
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}
/**
 * deciding what to show on the screen
 * for small display, display either select entry, select saved resume or resume at a time
 * for medium display, always display resume, display select entry or select saved resume at a time
 * for large display, alwasy display all three
 *
 * @param {*} screenSize
 * @param {*} previousState
 * @param {*} buttonAction
 * @param {*} buttonValue
 * @returns
 */
export function decidingState(
  screenSize,
  previousState,
  buttonAction,
  buttonValue,
) {
  const result = { ...previousState };
  if (buttonAction === 'selectedCategory') {
    result.selectedCategory = buttonValue;
    // buttonAction = 'showEdit';
  } else result.selectedCategory = previousState.selectedCategory;
  if (screenSize <= 900) {
    result.showEdit = false;
    result.showResume = false;
    result.showSaveResume = false;
    result[buttonAction] = true;
  } else if (screenSize < 1200 && screenSize > 900) {
    if (buttonAction !== 'showResume') {
      result.showResume = true;
      result.showEdit = false;
      result.showSaveResume = false;
      result[buttonAction] = true;
    }
  } else {
    result.showEdit = true;
    result.showResume = true;
    result.showSaveResume = true;
  }
  return result;
}

export function initState(screenSize, defaultCategory) {
  return decidingState(screenSize, {}, 'selectedCategory', defaultCategory);
}

export function clearToken() {
  sessionStorage.removeItem('accessToken');
  Cookies.remove('accessToken');
}

export function getToken() {
  let token = sessionStorage.getItem('accessToken', null);
  if (token === null) {
    token = Cookies.get('accessToken');
  }
  return token;
}
/**
 * calculate a scale to fit resume page into its container
 */
export function calculateScale(pageClassName, containerClassName) {
  const containerWidth = document.getElementById(containerClassName).clientWidth;
  const pageWidth = document.getElementById(pageClassName).clientWidth;

  const containerHeight = document.getElementById(containerClassName).clientHeight;
  const pageHeight = document.getElementById(pageClassName).clientHeight;

  const widthScale = Math.min(containerWidth / pageWidth, 1);
  const heightScale = Math.min(containerHeight / pageHeight, 1);
  const scale = Math.min(widthScale, heightScale);
  // offset to show the whole border
  return scale - 0.01;
}

export function unixTimeToDateString(unixTime) {
  const date = new Date(unixTime * 1000);
  return date.toLocaleDateString();
}

/**
 * create object with empty value for each field
 * @param {*} template required filed for the user data
 * @returns {filed1 : '', field2: '', ...}
 */
export function createEmptyInfoData(template) {
  // empty value for each field
  const data = {};
  template.forEach((field) => {
    data[field] = '';
  });
  return data;
}

export function toAllCap(text) {
  let capText = text.split('_');
  capText = capText.join(' ');
  // return all cap
  return capText.toUpperCase();
}
