import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import React from 'react';

export default function ContentContainer({
  resumeIndex,
  resumeId,
  content,
  ref,
}) {
  function handleClick(e) {
    e.stopPropagation();
  }
  if (resumeIndex === undefined) {
    return (
      <Box
        onClick={(e) => handleClick(e, content)}
        ref={ref}
        sx={{
          display: 'flex',
          padding: '10px',
          height: '70%',
          width: '100%',
          overflow: 'hidden',
        }}
      >
        {content}
      </Box>
    );
  }
  return (
    <Link
      to={`/app/edit/${resumeId}`}
      style={{ color: 'inherit', textDecoration: 'inherit' }}
    >
      <Box
        onClick={(e) => handleClick(e, content)}
        ref={ref}
        sx={{
          display: 'flex',
          padding: '10px',
          height: '70%',
          width: '100%',
          overflow: 'hidden',
        }}
      >
        {content}
      </Box>
    </Link>
  );
}
