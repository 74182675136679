import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Form from 'components/UserInfoForm';
import { unixTimeToDateString } from 'utils';
import debounce from 'lodash.debounce';
import { updateResumeSource } from 'api';
import React from 'react';

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: "#A9A9A9",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
export default function UserFormInteractiveWraper({ formData, onChange }) {
  const [localFormData, setLocalFormData] = React.useState(formData);
  const { template, name } = formData;
  const selected = !!formData.selected;

  function handleToggleSelection() {
    onChange({
      ...localFormData,
      selected: !selected,
    });
  }

  const syncData = debounce((data) => {
    updateResumeSource(data);
  }, 1000);

  function handleFormChagne(e) {
    const newFormData = {
      ...localFormData.data,
      [e.target.id]: e.target.value,
    };
    setLocalFormData({
      ...localFormData,
      data: newFormData,
    });

    // sync with backend
    syncData({ id: localFormData.id, data: newFormData, name: localFormData.name });
  }

  return (
    <Item>
      <Box
        pl={1}
        pr={2}
        display="flex"
        flexDirection="row"
        alignContent="center"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
          {name}
        </Typography>

        <Checkbox checked={selected} onChange={() => handleToggleSelection()} />
      </Box>
      <Typography
        variant="subtitle2"
        color="text.secondary"
        gutterBottom
        align="left"
        pl={1}
        pr={2}
      >
        {unixTimeToDateString(formData.last_edit_date)}
      </Typography>
      <Form
        template={template}
        onChange={(e) => handleFormChagne(e)}
        data={localFormData.data}
      />
    </Item>
  );
}
