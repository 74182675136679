import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import IosShareIcon from '@mui/icons-material/IosShare';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
/**
 * btn group for add remove edit
 * @param {*} param0
 * @returns
 */
export default function CARBtnGroup({ count, onClick }) {
  function handleAddToResume() {
    onClick('add');
  }
  function handleDeleteForever() {
    onClick('delete');
  }
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Tooltip title="Add to resume">
        <IconButton aria-label="Add" onClick={() => handleAddToResume()}>
          <IosShareIcon />
        </IconButton>
      </Tooltip>
      <Typography variant="subtitle1" gutterBottom>
        {count}
        {' '}
        items Selected
      </Typography>
      <Tooltip title="Delete forever">
        <IconButton aria-label="delete" onClick={() => handleDeleteForever()}>
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
