import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import Grid from '@mui/material/Grid';

import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';

import AnimatedResumeCard from 'components/AnimatedResumeCard';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { createNewResume, getAllResumes, deleteResumeWithId } from '../api';

import ResumeCard from './resumeCard/ResumeCard';

import JobDescriptionModel from './JobDescriptionModal';
import SearchBar from './SearchBar';
// const darkTheme = createTheme({ palette: { mode: "dark" } });
const lightTheme = createTheme({ palette: { mode: 'light' } });
const fadeTime = 500;

function CreateResumeFromJobDescriptionBtn({ onClick }) {
  return (
    <Box sx={{ height: 1 }}>
      <Button
        sx={{ fontSize: '80%', color: 'black' }}
        size="small"
        variant="text"
        onClick={onClick}
      >
        Create From Job Description
      </Button>
    </Box>
  );
}

/**
 * This componnet always display first in the resume galary to let user
 * crreate a new resume
 * @returns a resume card with a plus icon
 */
function Logo({ onClick }) {
  return (
    <Box
      sx={{
        alignSelf: 'center',
        height: '100px',
        display: 'flex',
      }}
    >
      <IconButton
        sx={{
          size: 'large',
          borderRadius: '0',
          height: '100%',
          width: '100%',
        }}
        onClick={onClick}
      >
        <NoteAddOutlinedIcon fontSize="large" />
      </IconButton>
    </Box>
  );
}

function CreateNewResumeCard({ createEmptyResume, createFromJobDescription }) {
  return (
    <ResumeCard
      content={<Logo onClick={createEmptyResume} />}
      btnGroup={<CreateResumeFromJobDescriptionBtn onClick={createFromJobDescription} />}
    />
  );
}
/**
 *
 * create a btn group contain delete, download and duplicate
 * @param id is the index of the resume in rresumeList
 * @param name is the title of the resume
 * @param handleDelete is the function to handle delete button
 * @param handleDownload is the function to handle download button
 * @param handleDuplicate is the function to handle duplicate button
 * @return <ButtonGroup> from MUI with delete, download and duplicate button
 */
function _createBtnGroup(
  name,
  id,
  handleDelete,
  handleDownload,
  handleDuplicate,
) {
  function onClick(action) {
    if (action === 'delete') {
      handleDelete(id);
    } else if (action === 'download') {
      handleDownload(name);
    } else if (action === 'duplicate') {
      handleDuplicate(id);
    }
  }
  return (
    <ButtonGroup
      size="small"
      variant="outlined"
      aria-label="outlined button group"
    >
      <Tooltip title="Move to trash">
        <IconButton aria-label="delete" onClick={() => onClick('delete')}>
          <DeleteOutlineOutlinedIcon />
          {' '}
        </IconButton>
      </Tooltip>
      <Tooltip title="Download">
        <IconButton aria-label="download" onClick={() => onClick('download')}>
          <DownloadOutlinedIcon />
          {' '}
        </IconButton>
      </Tooltip>
      <Tooltip title="Make another copy">
        <IconButton
          aria-label="duplicate"
          onClick={() => onClick('duplicate')}
        >
          <ContentCopyOutlinedIcon />
          {' '}
        </IconButton>
      </Tooltip>
    </ButtonGroup>
  );
}

/**
 * create a resume card which has two part the content container to hold the card title
 * and the button container to hold the button group
 * @returns card with content and button group
 */
export default function ResumeGalary() {
  const [searchText, setSearchText] = React.useState('');
  const navigate = useNavigate();
  const [openModal, setOpenModal] = React.useState(false);
  const [resumes, setResumes] = React.useState(undefined);
  const theme = lightTheme;
  const [removeAt, setRemoveAt] = React.useState(-1);
  // fetch resumes from DB
  React.useEffect(() => {
    async function requestResumes() {
      const response = await getAllResumes();

      // error handling
      if (response.status !== 200) {
        console.log('error');
        return;
      }

      const resumeCardData = response.data.map((resume) => ({
        name: resume.name,
        id: resume.id,
        fadeIn: true,
        lastEdit: resume.last_edit_date,
      }));

      // sort resumeCardData based on lastEdit
      resumeCardData.sort((a, b) => b.lastEdit - a.lastEdit);

      setResumes(resumeCardData);
    }
    requestResumes();
  }, []);

  function handleDownloadResume(resumeName) {
    console.log(`download${resumeName}`);
  }

  /**
   * handle delete action from button group
   * this trigger the fade out animation to be played
   * actuall remove is in the handleTransitionEnd
   * @param {*} removeAt
   */
  function handleDeleteResume() {
    // trigger animation
    setRemoveAt(removeAt);
    // set to play fade out animation on the resume card to be reomoved
    setResumes((prev) => prev.map((resume, index) => {
      if (index === removeAt) {
        return { ...resume, fadeIn: false };
      } return resume;
    }));
  }
  /**
   * Add a new resume at resumeList[1]
   * becuase the resume is displayed at chronological order
   * Then redirect to the editor
   *
   */
  function handleCreateNewResume() {
    async function _createResume() {
      // TODO fix this
      const response = await createNewResume();
      if (response.status !== 200) {
        console.log('error');
        return;
      }
      // TODO: do I still need this?
      setResumes((prev) => [
        {
          name: response.data.name,
          id: response.data.id,
          fadeIn: true,
          lastEdit: response.data.last_edit_date,
        },
        ...prev,
      ]);
      navigate(`/app/edit/${response.data.id}`);
    }
    _createResume();
  }

  function handleDuplicateResume(duplicateAt) {
    async function _duplicateResumeAt() {
      const response = await createNewResume(resumes[duplicateAt].id);

      const resumeCardData = {
        name: response.data.name,
        id: response.data.id,
        fadeIn: true,
        lastEdit: response.data.last_edit_date,
      };
      setResumes((prev) => [resumeCardData, ...prev]);
    }
    _duplicateResumeAt(duplicateAt);
  }
  function handleSearchBarInput(newInput) {
    setSearchText(newInput);
  }
  function handleTransitionEnd(node, done, fadeIn) {
    if (fadeIn) {
      setTimeout(done(), fadeTime);
    } else {
      const removedResume = resumes[removeAt];
      setTimeout(() => {
        // add resume to trash
        // TODO implment trash on backend
        const newResumes = [
          ...resumes.slice(0, removeAt),
          ...resumes.slice(removeAt + 1),
        ];

        const trashes = JSON.parse(localStorage.getItem('trash') || '[]');
        localStorage.setItem(
          'trash',
          JSON.stringify([...trashes, removedResume]),
        );
        setResumes(newResumes);
        // remove from cloud
        deleteResumeWithId(removedResume.id);
        done();
      }, fadeTime);
    }
  }

  const resumeList = [
    <CreateNewResumeCard
      key="new"
      createFromJobDescription={() => setOpenModal(true)}
      createEmptyResume={() => handleCreateNewResume()}
    />,
  ];
  // filter
  const filteredResumes = React.useMemo(() => {
    // empty and undefine return all resumes
    if (searchText === '') return resumes;
    return resumes.filter((resume) => resume.name.toLowerCase().includes(searchText.toLowerCase()));
  }, [resumes, searchText]);
  // first resume card is alwasy add new resume
  if (resumes === undefined) return <div>Loading...</div>;
  for (let i = 0; i < filteredResumes.length; i += 1) {
    const resumeName = filteredResumes[i].name;
    const btnGroup = _createBtnGroup(
      resumeName,
      i,
      handleDeleteResume,
      handleDownloadResume,
      handleDuplicateResume,
    );
    resumeList.push(
      AnimatedResumeCard(filteredResumes[i], i, btnGroup, handleTransitionEnd),
    );
  }
  console.log(resumes);

  return (
    <>
      <SearchBar
        content={searchText}
        handleSearchBarInput={(newInput) => handleSearchBarInput(newInput)}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ThemeProvider theme={theme}>
            <Box
              sx={{
                p: 2,
                bgcolor: 'background.default',
                display: 'flex',
                flexWrap: 'wrap',
                rowGap: 2,
                columnGap: 2,
              }}
            >
              {resumeList}
            </Box>
          </ThemeProvider>
        </Grid>
      </Grid>
      <JobDescriptionModel open={openModal} setOpen={setOpenModal} />
    </>
  );
}
