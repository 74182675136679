import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import SideBar from 'components/SideBar';
import { Outlet } from 'react-router-dom';

export const UserContext = React.createContext(null);
export default function App() {
  // const [searchText, setSearchText] = React.useState("");
  const [expandSideBar, setExpandSideBar] = React.useState(true);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* <MiniDrawer /> */}
      <SideBar expand={expandSideBar} setExpand={setExpandSideBar} />
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
      >
        <Outlet />
      </Box>
    </Box>
  );
}
