import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

export default function InfoActionBtn({
  infoForm,
  onMoveInfoUp,
  onMoveInfoDown,
  onDeleteInfo,
  isFirst,
  isLast,
}) {
  // TODO
  // In order to know display move up and move down. Need to know the current position
  // if it is the last one hide move down, if it is the first one then hide move up
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="end"
      width="1"
      justifyContent="space-between"
    >
      <ButtonGroup>
        {!isFirst && (
          <Tooltip title="move this information up">
            <IconButton aria-label="move up" onClick={onMoveInfoUp}>
              <ArrowUpwardIcon />
            </IconButton>
          </Tooltip>
        )}
        {!isLast && (
          <Tooltip title="move this information down">
            <IconButton aria-label="move down" onClick={onMoveInfoDown}>
              <ArrowDownwardIcon />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="delete this information">
          <IconButton aria-label="delete" onClick={onDeleteInfo}>
            <DeleteForeverIcon />
          </IconButton>
        </Tooltip>
      </ButtonGroup>
      {infoForm}
    </Box>
  );
}
