import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

/**
 * Dispaly a user information using template
 * @param props:
 * {
 *  template: [["field1", "width"], ["field2", "width"], ...]
 *  onChange: function to value change
 *  data: {field1: value1, field2: value2, ...}
 * }
 * @returns a form to present user information
 */
export default function UserInfoForm({ template, onChange, data }) {
  return (
    <Box
      onChange={onChange}
      component="form"
      sx={{
        width: '100%',
        '& .MuiTextField-root': { m: '1%' },
      }}
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      alignItems="flex-start"
      noValidate
      autoComplete="off"
    >
      {template.map((field) => (
        <TextField
          value={field[0] in data ? data[field[0]] : ''}
          key={field[0]}
          id={field[0]}
          label={field[0]}
          multiline
          maxRows={5}
          sx={{ width: field[1] }}
        />
      ))}
    </Box>
  );
}
